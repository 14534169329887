import * as Scrivito from 'scrivito';
import { PageTemplate } from '../../components/pages/page-template/page-template';
import { PressRelease, PressReleaseObjectClass } from './press-release-class';
import { PressReleaseAttributes } from './press-release-definitions';
import styles from './press-release.module.scss';
import dayjs from 'dayjs';
import { isPageVisible } from '../../utils/scrivito/schedule-validation';
import { PageError404 } from '../../components/pages/page-error404/page-error404';
import { DATE_FORMAT, DATE_FORMAT_INT } from '../../utils/general.utils';

export const PressReleaseComponent: React.FC<{ page: PressRelease }> = Scrivito.connect(({ page }) => {
  const editMode = Scrivito.isInPlaceEditingActive();
  const scheduleInfo = isPageVisible(page);

  if (!editMode && !scheduleInfo.visible) {
    return <PageError404 />;
  }

  const dateTime = (page.get(PressReleaseAttributes.DATE) as Date) ?? new Date();
  const headlineElement = (page.get(PressReleaseAttributes.HEADLINE_ELEMENT) as string) ?? 'h1';
  const publishedDate = dayjs(dateTime);

  return (
    <PageTemplate className="PressRelease" scheduleInfo={scheduleInfo}>
      <div>
        <div>
          <div>
            <time dateTime={publishedDate.format(DATE_FORMAT_INT)}>{publishedDate.format(DATE_FORMAT)}</time>
          </div>
          <div>
            <Scrivito.ContentTag content={page} attribute={PressReleaseAttributes.SUBLINE} tag="p" />
          </div>
          <div>
            <Scrivito.ContentTag
              content={page}
              attribute={PressReleaseAttributes.HEADLINE}
              tag={headlineElement}
              className={styles.PressReleaseTitle}
            />
          </div>
          <div>
            <Scrivito.ContentTag
              content={page}
              attribute={PressReleaseAttributes.BUTTONS}
              className={styles.PressReleaseButtons}
            />
          </div>
        </div>
        <div>
          <div>
            <Scrivito.ContentTag content={page} attribute="body" />
          </div>
        </div>
      </div>
    </PageTemplate>
  );
});

Scrivito.provideComponent(PressReleaseObjectClass, PressReleaseComponent);
