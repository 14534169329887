import * as Scrivito from 'scrivito';
import { LandingSubPage, LandingSubPageObjectClass } from './landing-sub-page-class';
import { LandingPageTemplate } from '../../components/pages/landing-page-template/landing-page-template';
import { isPageVisible } from '../../utils/scrivito/schedule-validation';
import { LandingPageError404 } from '../../components/pages/landing-page-template/landing-page-template-error404';

export const LandingSubPageComponent: React.FC<{ page: LandingSubPage }> = ({ page }) => {
  const editMode = Scrivito.isInPlaceEditingActive();
  const scheduleInfo = isPageVisible(page);

  if (!editMode && !scheduleInfo.visible) {
    return <LandingPageError404 />;
  }

  return (
    <LandingPageTemplate scheduleInfo={scheduleInfo}>
      <Scrivito.ContentTag content={page} attribute="body" />
    </LandingPageTemplate>
  );
};

Scrivito.provideComponent(LandingSubPageObjectClass, LandingSubPageComponent);
