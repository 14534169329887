import * as Scrivito from 'scrivito';
import { PageTemplate } from '../../components/pages/page-template/page-template';
import { HomePageObjectClass } from './home-page-class';
import { ContentPage } from '../content-page';

export const HomePageComponent: React.FC<{ page: ContentPage }> = ({ page }) => {
  return (
    <PageTemplate>
      <Scrivito.ContentTag content={page} attribute="body" />
    </PageTemplate>
  );
};

Scrivito.provideComponent(HomePageObjectClass, HomePageComponent);
