import * as Scrivito from 'scrivito';
import { PageTemplate } from '../../components/pages/page-template/page-template';
import { ContentPage, ContentPageObjectClass } from './content-page-class';
import { isPageVisible } from '../../utils/scrivito/schedule-validation';
import { PageError404 } from '../../components/pages/page-error404/page-error404';

export const ContentPageComponent: React.FC<{ page: ContentPage }> = ({ page }) => {
  const editMode = Scrivito.isInPlaceEditingActive();
  const scheduleInfo = isPageVisible(page);

  if (!editMode && !scheduleInfo.visible) {
    return <PageError404 />;
  }

  return (
    <PageTemplate scheduleInfo={scheduleInfo}>
      <Scrivito.ContentTag content={page} attribute="body" />
    </PageTemplate>
  );
};

Scrivito.provideComponent(ContentPageObjectClass, ContentPageComponent);
