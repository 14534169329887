import * as Scrivito from 'scrivito';
import { PageTemplate } from '../../components/pages/page-template/page-template';
import { OverviewPage, OverviewPageObjectClass } from './overview-page-class';
import { isPageVisible } from '../../utils/scrivito/schedule-validation';
import { PageError404 } from '../../components/pages/page-error404/page-error404';
import { PressReleaseAttributes } from '../press-release';
import styles from './overview-page.module.scss';
import { OverviewPageAttributes } from './overview-page-definitions';
import { useTranslation } from 'react-i18next';
import { FilterTagComponent } from '../../components/controls/filter-tag/filter-tag';
import { FilterTagGroupComponent } from '../../components/controls/filter-tag-group/filter-tag-group';
import { DEFAULT_RESULT_LIMIT, getSearchResults, hasResults, addOrRemoveFilter } from './overview-utils.scrivito';
import { PressReleasePreview } from '../../components/controls/press-release-preview/press-release-preview';
import React, { useState } from 'react';
import { ButtonComponent } from '../../components/controls/button/button';

export const OverviewPageComponent: React.FC<{ page: OverviewPage }> = ({ page }) => {
  // Elements that will be displayed in the overview – for now this will be PressReleaseIds only
  const elements = (page.get(OverviewPageAttributes.ELEMENTS) as string[]) ?? [];
  // Filters that are displayed in case an element has one of this tags
  const filters = (page.get(OverviewPageAttributes.FILTERS) as string[]) ?? [];
  const [limit, setLimit] = useState(DEFAULT_RESULT_LIMIT);
  const editMode = Scrivito.isInPlaceEditingActive();
  const scheduleInfo = isPageVisible(page);
  const { t } = useTranslation();

  // Get the URL parameter
  const urlSearchParams = new URLSearchParams(document.location.search);
  const urlFilter = urlSearchParams.get('filter');
  const tags = urlFilter ? urlFilter.split(',') : [];

  if (!editMode && !scheduleInfo.visible) {
    return <PageError404 />;
  }

  // Get results for elements (in this case only press releases)
  const pressReleases =
    elements.length > 0 ? getSearchResults(page.path(), elements, tags, limit) : { items: [], total: 0 };

  // Sets or removes the filter from the url on tag click
  const onTagClick = (_: React.MouseEvent, filter: string): void => {
    const filterQuery = addOrRemoveFilter(tags, filter);
    Scrivito.navigateTo(() => Scrivito.currentPage(), { filter: filterQuery });
  };

  return (
    <PageTemplate scheduleInfo={scheduleInfo}>
      <div className={styles.OverviewPage} style={{ display: 'block' }}>
        <div>
          <Scrivito.ContentTag content={page} attribute={OverviewPageAttributes.HEADLINE} tag={'h1'} />
        </div>
        {pressReleases.total > 0 && (
          <div>
            <FilterTagGroupComponent label={t('search.filtering')} className={styles.Filter}>
              {filters.map((filter, index) => {
                if (!hasResults(page.path(), elements, [filter])) {
                  return undefined;
                }
                return (
                  <FilterTagComponent
                    value={filter}
                    label={filter}
                    selected={tags.indexOf(filter) !== -1}
                    key={index}
                    onClick={(event, filter): void => onTagClick(event, filter)}
                  />
                );
              })}
            </FilterTagGroupComponent>
          </div>
        )}
        <div>
          <ul className={styles.PressReleasePreviewList}>
            {pressReleases.total > 0 &&
              pressReleases.items.map((page: Scrivito.Obj, index) => {
                return (
                  <PressReleasePreview
                    key={index}
                    headline={page.get(PressReleaseAttributes.HEADLINE) as string}
                    subHeadline={page.get(PressReleaseAttributes.SUBLINE) as string}
                    link={page}
                    date={page.get(PressReleaseAttributes.DATE) as Date}
                  />
                );
              })}
          </ul>
        </div>
        {pressReleases.total > pressReleases.items.length && (
          <div className={styles.LoadMore}>
            <ButtonComponent
              onClick={(): void => setLimit(limit + DEFAULT_RESULT_LIMIT)}
              href={null}
              color={'primary'}
              content={<span>{t('search.loadMore')}</span>}
              small={false}
            />
          </div>
        )}
      </div>
    </PageTemplate>
  );
};

Scrivito.provideComponent(OverviewPageObjectClass, OverviewPageComponent);
