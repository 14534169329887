import React from 'react';
import * as Scrivito from 'scrivito';
import './pages';
import './objects';
import './widgets';
import './index.scss';
import App from './App';
import { configureScrivito } from './config/scrivito';
import { createRoot, hydrateRoot } from 'react-dom/client';
import './i18n';

declare global {
  interface Window {
    scrivitoPreloadDump: string;
    scrivitoPrerenderReady: boolean;
    Scrivito: typeof Scrivito;
    dataLayer: Array<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  }
}

configureScrivito();

// Add Scrivito to a global scope to make the SDK calls available in the browser console
window.Scrivito = Scrivito;

const rootElement = document.getElementById('root') as HTMLElement;

/**
 * Renders the single page application using ReactDOM.render
 * This is the default call to mount react single page apps.
 */
function renderReactSPA(): void {
  const root = createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App callback={undefined} />
    </React.StrictMode>
  );
}

/**
 * Hydrates an existing prerendered React App and updates
 * the preloaded content to the currently published content
 * to make sure that the content is always up-to-date.
 */
function hydrateScrivitoApp(): void {
  hydrateRoot(rootElement, <App callback={(): void => Scrivito.updateContent()} />);
}

function bootstrapScrivitoApp(): void {
  if (window.scrivitoPreloadDump) {
    // Preload the content inside a preload dump
    Scrivito.preload(window.scrivitoPreloadDump).then(({ dumpLoaded }) => {
      // If the preload dump has been loaded, rehydrate the React app.
      // otherwise the React app will be rendered without a preload dump.
      dumpLoaded ? hydrateScrivitoApp() : renderReactSPA();
    });
  } else {
    window.scrivitoPrerenderReady = false;
    renderReactSPA();
    Scrivito.finishLoading().then(() => {
      window.scrivitoPrerenderReady = true;
    });
  }
}

bootstrapScrivitoApp();
