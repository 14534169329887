import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material';
import classNames from 'classnames';
import { KeyboardEventHandler, MouseEventHandler, ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconChevron } from '../../../assets/icons/chevron.svg';
import { Message } from '../../../utils/translations';
import { DropdownItemProps, DropdownWidgetId } from '../../../widgets/dropdown-widget';
import style from './dropdown.module.scss';
import { ReactComponent as IconClose } from '../../../assets/icons/icon-close.svg';

/**
 * Properties for a dropdown menu
 */
export interface DropdownComponentProps extends SelectProps {
  items: DropdownItemProps[];
  errorMessage?: string;
  onValueChange?: (item: DropdownItemProps) => void;
  optional?: boolean;
  hiddenLabel?: string;
}

/**
 * Renders a dropdown component
 */
export const DropdownComponent: React.FC<DropdownComponentProps> = ({
  items,
  label,
  hiddenLabel,
  errorMessage,
  onValueChange,
  optional = false,
  ...props
}) => {
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState<string>((props.defaultValue as DropdownItemProps)?.label ?? '');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLElement | null>(null);

  const handleDropdown = (): void => {
    setIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => handleDropdown());

    return () => {
      window.removeEventListener('scroll', () => handleDropdown());
    };
  }, []);

  const handleChange = (event: SelectChangeEvent): void => {
    const newlySelectedItem = items.find((item) => item?.label === event.target.value);
    setSelectedItem(newlySelectedItem?.label ?? '');
    onValueChange && newlySelectedItem && onValueChange(newlySelectedItem);
  };

  /**
   * clear value of input.
   */
  const clearValue = (): void => {
    const emptyValue: DropdownItemProps = {
      value: '',
      label: '',
    };
    setSelectedItem(emptyValue.label);
    onValueChange && onValueChange(emptyValue);
  };

  /**
   * clear value of dropdown.
   * */
  const onClickClearButton: MouseEventHandler = (event): void => {
    event.preventDefault();
    clearValue();
  };

  /**
   * Remove input value via keyboard
   * @param event
   */
  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event): void => {
    if (props.disabled || !optional) {
      return;
    } else if (event.key === 'Delete' || event.key === 'Backspace') {
      clearValue();
    }
  };

  return (
    <div className={classNames(style.DropdownContainer, 'Dropdown', { [style.Disabled]: props.disabled })}>
      <FormControl fullWidth onKeyDown={handleKeyDown} data-testid={`${DropdownWidgetId}_form-control`}>
        {label && (
          <InputLabel shrink={isOpen || selectedItem !== ''}>
            {label}
            {optional && <span className={style.OptionalLabel}> {t('component.forms.optional')}</span>}
          </InputLabel>
        )}
        {optional && selectedItem && (
          <button
            type="button"
            data-testid={`${DropdownWidgetId}_clear-button`}
            className={style.ClearButton}
            onClick={onClickClearButton}
            disabled={props.disabled}
            tabIndex={-1}
            title={t('component.dropdown.clearValue')}
            aria-label={t('component.dropdown.clearValue')}
          >
            <IconClose aria-hidden="true" />
          </button>
        )}
        <Select
          {...props}
          data-testid={DropdownWidgetId}
          className={classNames(style.DropdownWidget, props.className, {
            [style.ItemSelected]: selectedItem !== '',
            [style.IsOpen]: isOpen,
            [style.HasLabel]: label,
            [style.Optional]: optional,
          })}
          value={selectedItem}
          fullWidth
          displayEmpty={isOpen && props.placeholder !== '' && selectedItem === ''}
          defaultValue={undefined}
          onChange={handleChange}
          IconComponent={(props): ReactElement => <IconChevron {...props} />}
          renderValue={(selectedItem): React.ReactNode => {
            if (selectedItem.length === 0) {
              return <span className={style.Placeholder}>{props.placeholder}</span> || '';
            }

            return selectedItem;
          }}
          MenuProps={{ className: style.DropdownMenu }}
          error={props.error && !!errorMessage && !optional}
          onOpen={(): void => setIsOpen(true)}
          onClose={(): void => setIsOpen(false)}
          ref={dropdownRef}
          inputProps={{ 'data-value-text': selectedItem, 'aria-label': hiddenLabel, ...props.inputProps }}
        >
          {items?.length > 0 &&
            items.map((item: DropdownItemProps, index: number) => {
              return (
                // we use the label as unique identifier value while the actual value
                // in the DropdownItemProps might be used for further purposes
                <MenuItem key={index} value={item.label} className={style.DropdownMenuItem}>
                  {item.label}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      {errorMessage && props.error && !optional && (
        <FormHelperText className={style.ErrorText}>{errorMessage || Message.LIST_HAS_DUPLICATES}</FormHelperText>
      )}
    </div>
  );
};
